import { t, Trans } from "@lingui/macro"
import React from "react"
import { Seo } from "../components/seo"
import { BaseLayout } from "../layouts/base-layout"

const SupportPage = () => {
  return (
    <BaseLayout>
      <Seo
        title={t`Support`}
        description={t`Find answers to your Testframe questions and get the support you need from our helpful team. Contact us for assistance with any issues or concerns.`}
      />

      <h1 className="text-4xl font-extrabold">
        <Trans>Support</Trans>
      </h1>

      <div className="mt-3">
        <Trans>
          For any questions, support requests and feedback, please contact us
          using the following E-Mail
        </Trans>
        {": "}
        <a href="mailto:support@testframe.app">support@testframe.app</a>
      </div>
    </BaseLayout>
  )
}

export default SupportPage
